import * as React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { Container, Tabs, Tab } from "react-bootstrap"
import EventCard from './card'
import _ from 'lodash';
import moment from 'moment-timezone'

const List = () => {
    return (
        <StaticQuery
            query={graphql`
                query EventsQuery {
                    allEvent (
                        sort: { fields: [event_starts_at], order: ASC }
                    ) {
                        edges {
                            node {
                                id
                                title
                                description
                                location
                                venue
                                venue_address_1
                                venue_address_2
                                google_maps_url
                                date
                                event_starts_at
                                doors_open_at
                                display_timezone
                                thumbnail
                                tickets_url
                                stream_url
                                stream_embed
                            }
                        }
                    }
                    allEventDate(
                        sort: { fields: [date], order: ASC }
                    ) {
                        edges {
                            node {
                                id
                                date
                                category
                            }
                        }
                    }
                    allCategory {
                        edges {
                            node {
                                id
                                short_name
                                long_name
                                theme_color
                            }
                        }
                    }
                }
            `}
            render={(data) => {

                const now = moment().tz('US/Eastern')
                let nextDate;
                for(let { node } of data.allEventDate.edges) {
                    if(typeof(node.category) === 'string') { // only proceed on initial render
                        const _date = moment(node.date, "MM/DD/YYYY").tz('US/Eastern')
                        node.date_string = _date.format("MMMM D")
                        if(_date.isAfter(now) && (!nextDate || _date.isBefore(nextDate))) {
                            node.next_up = true
                            nextDate = _date
                        }
                        const category = data.allCategory.edges.find(({ node: { short_name } }) => short_name === node.category)
                        node.category = category ? category.node : null
                    }
                }
                const defaultActiveKey = nextDate ? nextDate.format("MMMM D") : null

                for(let { node } of data.allEvent.edges) {
                    if(typeof(node.date) === 'string') { // only proceed on initial render
                        node.date_string = moment(node.date, "MM/DD/YYYY").tz('US/Eastern').format("MMMM D")
                        let date = data.allEventDate.edges.find(({ node: { date } }) => date === node.date)
                        node.date = date ? date.node : null
                    }
                }

                const groups = _.groupBy(data.allEvent.edges, ({ node: { date_string } }) => { return date_string });

                let tabs = []


                for(let { node: { date_string, category } } of data.allEventDate.edges) {
                    console.log(date_string)
                    
                    let events = groups[date_string]

                    let title = (
                        <>
                            {category.long_name}
                            <span className="event-dateline">{date_string}</span>
                        </>
                    )

                    tabs.push(
                        <Tab 
                            key={date_string} 
                            eventKey={date_string} 
                            title={title}
                            tabClassName={`category-${category.theme_color}`}>
                            {events.map(({ node }) => {
                                return (
                                    <EventCard key={node.id} event={node} />
                                )
                            })}
                        </Tab>
                    )
                }

                return (
                    <div id="events" className="bg-black section">
                        <Container>
                            <h3 className="section-heading display-6">
                                <span>Events</span>
                            </h3>
                            <Tabs 
                                className="events-tabs" 
                                variant="pills"
                                defaultActiveKey={defaultActiveKey}>
                                {tabs}
                            </Tabs>
                        </Container>
                    </div>
                )
            }}
            />
    )
}
export default List;


