import * as React from "react";
import { Card, Button } from "react-bootstrap";
import moment from "moment-timezone";
import VideoPlayer from "../videos/player";

class EventCard extends React.Component {
  // const EventCard = (event) => {
  constructor(props) {
    super(props);

    let event = props.event;

    event.display_timezone = event.display_timezone || "US/Eastern";

    event.event_starts_at = moment(event.event_starts_at).tz(
      event.display_timezone
    );

    event.doors_open_at = event.doors_open_at
      ? moment(event.doors_open_at).tz(event.display_timezone)
      : null;

    event.upcoming = event.event_starts_at.isAfter(
      moment().tz(event.display_timezone)
    );
    event.stream_starts_at = moment(event.stream_starts_at).tz(
      event.display_timezone
    );
    event.stream_available =
      event.stream_url &&
      event.stream_url.match(/^https?:\/\//) &&
      event.stream_starts_at.isAfter(moment());
    event.venue_link =
      event.google_maps_url &&
      event.google_maps_url.match(/^https?:\/\//) &&
      event.google_maps_url;
    event.has_video =
      event.stream_embed && event.stream_embed.match(/^<iframe/);
    this.state = {
      event,
    };
  }

  render() {
    const event = this.state.event;

    const venueKlass = `event-card-location ${`text-category-${event.date.category.theme_color}`}`;

    let venueMarkup = (
      <>
        <i className="fa fa-map-marker"></i>
        {event.venue && <>{event.venue} &bull; </>}
        {event.location}
        {event.venue_address_1 && (
          <>
            <br />
            {event.venue_address_1}
          </>
        )}
        {event.venue_address_2 && (
          <>
            <br />
            {event.venue_address_2}
          </>
        )}
      </>
    );
    venueMarkup = event.venue_link ? (
      <a
        href={event.google_maps_url}
        className={venueKlass}
        target="_blank"
        rel="noopener noreferrer"
      >
        {venueMarkup}
      </a>
    ) : (
      <div className={venueKlass}>{venueMarkup}</div>
    );

    let doorsOpenStr = event.doors_open_at
      ? `${event.doors_open_at.format("MMM DD, h:mm A z")}${
          event.display_timezone !== "US/Eastern"
            ? ` / ${moment(event.doors_open_at)
                .tz("US/Eastern")
                .format("h:mm A z")}`
            : ""
        }`
      : "";
    let eventStartsStr = `${event.event_starts_at.format("MMM DD, h:mm A z")}${
      event.display_timezone !== "US/Eastern"
        ? ` / ${moment(event.event_starts_at)
            .tz("US/Eastern")
            .format("h:mm A z")}`
        : ""
    }${event.doors_open_at ? `: Panel & Livestream` : ""}`;
    let eventDateMarkup = (
      <>
        {event.doors_open_at && (
          <div className="event-card-time">
            {doorsOpenStr}: Doors &amp; Community Love
          </div>
        )}
        <div className="event-card-time">{eventStartsStr}</div>
      </>
    );

    return (
      <Card key={event.id} className="event-card">
        <Card.Body>
          <Card.Title as="h4">{event.title}</Card.Title>

          <div className="event-details-container">
            <div className="event-location-time-container">{venueMarkup}</div>

            <div className="event-cta-buttons-container">
              {event.upcoming && !event.tickets_url.match(/^https?:\/\//) && (
                <Button variant="primary" disabled={true} size="sm">
                  {event.tickets_url}
                </Button>
              )}
              {event.upcoming && event.tickets_url.match(/^https?:\/\//) && (
                <Button variant="primary" size="sm" href={event.tickets_url}>
                  Get Tickets
                </Button>
              )}
              {!event.stream_available &&
                !event.stream_url.match(/^https?:\/\//) && (
                  <Button variant="primary" disabled={true} size="sm">
                    {event.stream_url}
                  </Button>
                )}
              {event.stream_available && (
                <Button variant="primary" size="sm" href={event.stream_url}>
                  Watch Live Stream
                </Button>
              )}
            </div>
          </div>
          <div className="event-card-time-container">{eventDateMarkup}</div>
          <Card.Text>{event.description}</Card.Text>
        </Card.Body>
        {!event.has_video && (
          <Card.Img variant="bottom" src={event.thumbnail} />
        )}
        {event.has_video && (
          <div className="card-img-bottom">
            <VideoPlayer embed={event.stream_embed} />
          </div>
        )}
      </Card>
    );
  }
}
export default EventCard;
