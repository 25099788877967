import * as React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import CommunityCard from './card'
import _ from 'lodash';

const List = () => {
    return (
        <StaticQuery
            query={graphql`
                query CommunityQuery {
                    allCommunity {
                        edges {
                          node {
                            logo
                            name
                            url
                            id
                            description
                          }
                        }
                    }
                }
            `}
            render={(data) => {
                return (
                    <div id="community" className="pt-5">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={10}>
                                    <h3 className="section-heading alt display-6">
                                        <span>Community Love Row</span>
                                    </h3>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut etiam sit amet nisl purus in mollis. Eleifend mi in nulla posuere sollicitudin. Congue nisi vitae suscipit tellus mauris a diam maecenas. </p> */}
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                {data.allCommunity.edges.map(({ node }) => {  
                                    return (
                                        <Col key={node.id} md={4}>
                                            <CommunityCard key={node.id} {...node} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Container>
                    </div>
                )
            }}
        />
    )
}
export default List;


