import React from "react";
import { Nav } from "react-bootstrap";
import GPTUnit from './gpt-unit';


const Footer = () => {
    return (

        <footer>
            <GPTUnit slot="/4052/no.home" sizes={{ desktop: [[728, 90], [970, 250]], mobileweb: [[320, 50], [300, 250]] }} targeting={{ pos: ['bottom'], category: ['rtdrc'] }} />
            <Nav className="justify-content-center footer-nav">
                <Nav.Item>
                    <Nav.Link href="https://urban1.com">An Urban One Brand</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link id="ot-sdk-bng" className="ot-sdk-show-settings otSDKbtn" href="#">Do Not Sell my Personal Information</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://urban1.com/privacy/">Privacy Policy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://urban1.com/terms-of-service/">Terms of Service</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://forms.gle/zmBqZ6QWGhmzDxQ78">Contact Us</Nav.Link>
                </Nav.Item>
            </Nav>
        </footer>
    )
}
export default Footer;