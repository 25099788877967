import * as React from "react"
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout';
import EventsList from '../components/events/list';
import VideosList from '../components/videos/list';
import ArticlesList from '../components/articles/list';
import SupportersList from '../components/supporters/list';
import CommunityList from '../components/community/list';
import { Container, Row, Col, Ratio } from 'react-bootstrap';
// import { StaticImage } from 'gatsby-plugin-image';
import GPTUnit from '../components/misc/gpt-unit';
import heroAnimation from '../images/hero-animation.gif';
import VideoPlayer from '../components/videos/player';
import UpcomingEventCTA from '../components/events/upcoming-callout';

// markup
const IndexPage = ({ data }) => {
  const { node: featured_video } = data.allVideo.edges[0]

  return (
    <Layout>
      <div id="home" className="hero-container">
        <div className="clouds"></div>
        <Container>
          <Row  className="justify-content-center">
            <Col md={8}>
              <Ratio aspectRatio={553 / 868}>
              <img src={heroAnimation} className="img-fluid" alt="Newsone Presents: Real Talk Drives Real Change Fully-Charged by Chevrolet" />
              </Ratio>
            </Col>
          </Row>
        </Container>
        <div className="buildings">
          <div className="buildings-back"></div>
          <div className="buildings-middle"></div>
          <div className="buildings-front"></div>
        </div>
      </div>

      <div className="bg-black section">
        <Container>

          <GPTUnit slot="/4052/no.home" sizes={{ desktop: [[728, 90], [970, 250]], mobileweb: [[320, 50], [300, 250]] }} targeting={{ pos: ['top'], category: ['rtdrc'] }} />

          <Row className="justify-content-center">
            <Col md={8}>
              <UpcomingEventCTA />
              {featured_video && <VideoPlayer {...featured_video} />}
              
            </Col>
            
            <Col md={7} className="mt-5"> 
              <p>NewsOne is proud to team up with Chevrolet to create Real Talk Drives Real Change, a 4-part discussion series, to spark relevant conversations within the Black community and explore what is necessary to change for the better. This series includes live panel discussions on specific topics and live performances at each tour stop: Washington, D.C., Atlanta, Philadelphia, and Houston. Follow the conversation at #rtdrc #newsone, and follow us on <a href="https://www.facebook.com/NewsOneOfficial" target="_blank">Facebook</a>, <a href="https://www.instagram.com/newsone_official/" target="_blank">Instagram</a>, and <a href="https://twitter.com/newsone/" target="_blank">Twitter</a>.</p>
              <p>This is your home to get tickets, stream, support, and help. Together, we can drive Real Change.</p>
            </Col>
          </Row>
        </Container>      
      </div>
      <EventsList />
      <VideosList />
      <ArticlesList />
      <SupportersList />
      <CommunityList />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {    
    allVideo (
      filter: { featured_video: { eq: "TRUE" } },
      limit: 1,
    ) {
        edges {
            node {
                id
                published_at
                title
                description
                embed
                thumbnail
            }
        }
    }
  }
`

export default IndexPage
