import * as React from "react"
import { Card, Button } from "react-bootstrap"

const CommunityCard = ({ id, name, description, logo, url }) => {
    return (    
        <Card className="supporter-card">
            <div className="supporter-card-image">
                <Card.Img variant="top" src={logo} />
            </div>
            <Card.Body>
                <Card.Title as="h6" className="text-center pb-2">{name}</Card.Title>
                <Card.Text>
                    {description}
                </Card.Text>
                <div className="text-center">
                    {url && <Button variant="outline-black" href={url} target="_blank">Learn More</Button>}
                </div>
            </Card.Body>
        </Card>
    )
}
export default CommunityCard;


