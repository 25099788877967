import * as React from "react"
import { Card, Button } from "react-bootstrap"

const SupporterCard = ({ id, name, description, logo, help_url, support_url }) => {
    return (    
        <Card className="supporter-card">
            <div className="supporter-card-image">
                <Card.Img variant="top" src={logo} />
            </div>
            <Card.Body>
                <div className="text-center pb-3">
                    <Card.Title as="h6">{name}</Card.Title>
                    {support_url && <Button variant="primary" href={support_url} target="_blank">Give Support</Button>}
                    {help_url && <Button variant="primary" href={help_url} target="_blank">Find Help</Button>}
                </div>
                <Card.Text>
                    {description}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
export default SupporterCard;


