import * as React from "react"
import { Card } from "react-bootstrap"
import moment from 'moment-timezone';

const ArticleCard = (article) => {
    let { id, title, thumbnail, categories, creator, link } = article;
    let isoDate = moment(article.isoDate).tz('US/Eastern');
    return (    
        <Card className="article-card" key={id} as="a" href={link} target="_blank">
            <div className="article-card-image">
                <Card.Img variant="top" src={thumbnail} />
            </div>
            <Card.Body>
                <div className="article-category">{categories.join(', ')}</div>
                <Card.Title as="h6">{title}</Card.Title>
                <Card.Text className="article-byline">
                    {isoDate.format("DD MMM")} - {creator}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
export default ArticleCard;


