import * as React from "react"
import { Card } from "react-bootstrap"

const VideoCard = ({ id, title, thumbnail, description, published_at, embed, current, onClickHandler }) => {
    return (    
        <Card className={`video-card ${id} ${current ? 'current' : '' }`} key={id} style={{ maxWidth: 300 }} as="a" href="#video-player" onClick={() => { if(!current) { onClickHandler(id) } }}>
            <Card.ImgOverlay>
                <Card.Img variant="top" src={thumbnail} alt={title} title={title} />
                <i className="fa fa-play"></i>
            </Card.ImgOverlay>
            <Card.Text>
                {title}
            </Card.Text>
        </Card>
    )
}
export default VideoCard;


