import * as React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col, Button } from "react-bootstrap"
import ArticleCard from './card'
import _ from 'lodash';



class List extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            category: null
        }

        this.setCategory = this.setCategory.bind(this)
    }

    setCategory(category) {
        this.setState({ category })
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query ArticlesQuery {

                        allFeedNewsone(
                            filter: {link: {ne: "https://newsone.com/4175002/where-john-lewis-voting-rights-act-stands/"}}
                            sort: { fields: isoDate, order: DESC }
                        ) {
                            edges {
                                node {
                                    categories
                                    title
                                    isoDate
                                    creator
                                    id
                                    link
                                    media {
                                        thumbnail {
                                            attrs {
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        allCategory {
                            edges {
                                node {
                                    short_name
                                    long_name
                                    theme_color
                                    id
                                }
                            }
                        }
                    }
                `}
                render={(data) => {
                    let _this = this;
                    let articles = data.allFeedNewsone.edges.filter(article => { return this.state.category === null || article.node.categories.includes(this.state.category) });
                    let categories = data.allCategory.edges.map(category => { return category.node.short_name });
                    return (
                        <div id="articles" className="bg-yellow section">
                            <Container>
                                <Row className="justify-content-center">
                                    <Col md={8}>
                                        <h3 className="section-heading alt display-6">
                                            <span>Latest Articles</span>
                                        </h3>
                                        <div className="d-flex flex-wrap justify-content-center mb-4">
                                            <Button variant={_this.state.category ? 'outline-black' : 'primary'} onClick={() => { _this.setCategory(null) }}>All</Button>
                                            {data.allCategory.edges.map(({ node }) => {
                                                return (
                                                    <Button variant={_this.state.category === node.short_name ? 'primary' : 'outline-black'} key={node.id} onClick={() => { _this.setCategory(node.short_name) }}>{node.short_name}</Button>
                                                )
                                            })}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {articles.map(({ node }) => {  
                                        node.thumbnail = _.get(node, 'media.thumbnail.attrs.url', '')
                                        node.categories = _.intersection(node.categories, categories)
                                        return (
                                            <Col key={node.id} md={6}>
                                                <ArticleCard key={node.id} {...node} />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </Container>
                        </div>
                    )
                }}
            />
        )
    }
}
export default List;


