import * as React from "react"
import { Navbar as BootstrapNavbar, Nav, Container } from "react-bootstrap";
import Logo from './logo';

const Navbar = () => {
    return (
        <BootstrapNavbar collapseOnSelect bg="light" expand="lg" sticky="top">
            <Container>
                <BootstrapNavbar.Brand href="#home">
                    <Logo noLink={true} />
                </BootstrapNavbar.Brand>
                <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
                <BootstrapNavbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#events">Events</Nav.Link>
                        <Nav.Link href="#videos">Videos</Nav.Link>
                        <Nav.Link href="#articles">Articles</Nav.Link>
                        <Nav.Link href="#support">Support</Nav.Link>
                        <Nav.Link href="#community">Community Love</Nav.Link>
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    )
}
export default Navbar;