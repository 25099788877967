import React from 'react'

import ReactDOMServer from 'react-dom/server'
import { Link } from 'gatsby'

const Buffer = require('buffer/').Buffer

const svgs = {
  primary: {
    viewboxWidth: 553,
    viewboxHeight: 97,
    ratio: 553 / 97,
    g: ({ fill = '#000' }) => (
      <g>
        <path
          fill={fill} 
          d="M258.5,2.8c-1.1,4.3-2.2,8.6-3.4,12.9c-6.9,25.5-13.9,51-20.8,76.6c-0.5,1.8-1.2,2.6-3.2,2.5
	c-5.2-0.1-10.5-0.2-15.7,0c-2.1,0.1-2.9-0.8-3.4-2.7c-4.1-15.1-8.3-30.2-12.5-45.3c-0.2-1-0.7-1.9-1.4-2.7c-0.3,0.8-0.6,1.6-0.8,2.4
	c-4.3,15.2-8.6,30.5-12.7,45.7c-0.5,1.9-1.2,2.6-3.2,2.5c-5.4-0.1-10.8-0.1-16.2,0c-1.2,0.2-2.4-0.6-2.6-1.8c0,0,0,0,0-0.1
	c-8.1-29.9-16.2-59.7-24.4-89.5c0-0.3,0-0.6,0.1-0.9h3.3c6.2,0,12.3,0.1,18.5,0c1.8,0,2.4,0.6,2.8,2.3c3.6,15.6,7.3,31.2,11,46.7
	c0.2,1.1,0.7,2.1,1.5,2.9l4.2-16.7c2.8-11,5.6-22,8.3-33c0.4-1.6,1.1-2.1,2.7-2.1c5.2,0.1,10.5,0.1,15.7,0c1.6,0,2.3,0.4,2.7,2.1
	c3.9,15.7,7.8,31.4,11.8,47c0.2,0.8,0.5,1.6,0.8,2.5h0.7c0.9-3.7,1.9-7.4,2.7-11.2c3-12.7,6-25.5,9.1-38.2c0.3-1.1,0.4-2.2,2.1-2.2
	c7.2,0.1,14.3,0,21.5,0C257.9,2.6,258.2,2.7,258.5,2.8z M474.9,52.9V2.6h22.4v91.9c-1.2,0.1-2.4,0.2-3.6,0.2c-4.6,0-9.1-0.1-13.7,0
	c-1.6,0.1-3.1-0.7-3.8-2.1c-10.4-15.6-20.9-31.1-31.5-46.7c-0.5-0.8-1-1.5-2-2.8v51.3c-0.7,0.2-1.4,0.3-2.2,0.3
	c-6.1,0-12.1,0-18.2,0c-1.8,0-2.3-0.7-2.3-2.3c0-29.2,0-58.5,0-87.7c0-1.8,0.6-2.3,2.3-2.2c5.2,0.1,10.3,0.1,15.5,0
	c1.6-0.2,3.2,0.7,4,2.1c10.1,15.4,20.4,30.7,30.6,46.1c0.5,0.8,1.1,1.6,1.7,2.4L474.9,52.9z M0.3,2.5c6.6,0,13.1-0.1,19.5,0.1
	c0.7,0,1.5,1.1,2.1,1.9c10.3,15.5,20.7,31,31,46.5c0.5,0.7,1,1.4,1.5,2.1l0.7-0.2V2.6c0.9-0.1,1.7-0.2,2.5-0.2
	c5.7,0,11.5,0.2,17.2,0c2.4-0.1,2.9,0.8,2.9,3c-0.1,19,0,38.1,0,57.1c0,9.8,0,19.6,0,29.4c0,2.1-0.5,2.9-2.8,2.9c-5-0.2-10-0.1-15,0
	c-1.4,0.1-2.7-0.6-3.4-1.9C46,77.3,35.6,61.9,25.1,46.4c-0.5-0.8-1.1-1.5-1.7-2.3l-0.7,0.2v2.8c0,15,0,29.9,0,44.9
	c0,2.1-0.5,2.9-2.8,2.9c-5.9-0.2-11.8-0.1-17.7,0c-1.4-0.2-2.1-0.5-2.1-2.3c0.1-29.7,0.1-59.3,0.1-89C0.2,3.4,0.2,3.2,0.3,2.5z
	 M415.1,48.1c0,27-20.9,48.4-47.2,48.4c-26.1,0-47.6-21.9-47.6-48.6c-0.3-26.2,20.6-47.6,46.8-48s47.6,20.6,48,46.8
	C415.1,47.2,415.1,47.6,415.1,48.1z M368,23.3c-13.8-0.1-25.1,11-25.3,24.9c0,14,11.4,25.7,25.1,25.7c14,0,25.3-11.2,25.3-25.2
	S381.9,23.3,368,23.3z M315.9,28.7h-20.3c-0.3-1.1-0.5-2.2-0.8-3.2c-1.1-3.6-4.2-5.7-8.2-5.6c-3.2-0.1-6,1.9-7,4.9
	c-1.2,3.5,0.2,7.3,3.5,9c4.4,2.5,9,4.7,13.5,7.1c2.9,1.5,5.8,3.2,8.6,5c17.7,12.1,13.7,34,0.6,44.2c-7.4,5.7-15.9,7.2-25,6.2
	c-2-0.2-3.9-0.5-5.9-1c-12.3-3.2-19.6-12.4-20-25.3c-0.2-6.3-0.9-5.2,5.1-5.3c4.7-0.1,9.3,0,14,0c1.5,0,2.3,0.4,2.2,2.1
	c0,0.8,0.1,1.7,0.4,2.5c1.1,6.9,7.7,9.9,13.7,6.5c4.9-2.8,5-11,0.1-14.2c-2.7-1.7-5.8-2.9-8.7-4.3c-4.5-2.3-9.2-4.3-13.4-7
	c-9-6-13.4-14.5-11.8-25.4s8-18.2,18.3-22c8.6-3.1,17.3-3.3,25.8,0.3C310.8,7.3,317,17.7,315.9,28.7z M83.2,94.4V2.7h50v19.9h-27.4
	v15.3h27.3v20.2h-27.4c0,5.3-0.1,10.4,0.1,15.4c0,0.4,1.4,1.1,2.2,1.2c7.3,0.1,14.6,0.1,22,0.1h3.1c0.2,0.5,0.3,1,0.3,1.6
	c0,5.5,0,11,0,16.5c0,1.3-0.3,2-1.8,2c-15.9,0-31.8-0.1-47.7-0.1C83.7,94.6,83.4,94.5,83.2,94.4z M502.9,2.6h49.9v19.8
	c-1,0.1-2,0.2-3,0.2c-7.4,0-14.8,0.1-22.2,0c-1.9,0-2.5,0.6-2.5,2.4c0.1,4.2,0,8.4,0,12.8h27.5v20.3h-2.5c-7.5,0-15,0-22.5,0
	c-1.8,0-2.5,0.5-2.5,2.4c0.1,3.9,0.1,7.8,0,11.7c-0.1,2,0.8,2.5,2.7,2.5c7.3-0.1,14.7,0,22,0h3.1v19.8H503V2.6H502.9z"/>
      </g>
    ),
  },
}

function svg({
  viewboxWidth,
  viewboxHeight,
  g,
  fill,
  className,
  alt,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0  ${viewboxWidth} ${viewboxHeight}`}
      alt={alt}
      className={className}
    >
      {g({ fill })}
    </svg>
  )
}

// function svgString() {
//   return `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${viewboxWidth} ${viewboxHeight}'>${ReactDOMServer.renderToStaticMarkup(
//     g({ fillPrimary, fillSecondary })
//   )}</svg>`
// }

function svgBackgroundImage(args) {
  const svgString = ReactDOMServer.renderToStaticMarkup(svg(args)) //svgString(args)
  const buff = Buffer.from(svgString, 'utf-8')
  const base64 = buff.toString('base64')
  return `url("data:image/svg+xml;base64,${base64}")`
}


const Logo = (props) => {
  const {
    as = 'h1',
    title = 'Newsone',
    variant = 'primary',
    klass = '',
    ...svgOptions
  } = props
  const As = as

  const origSVG = svgs[variant]

  // default to SVG dimensions
  const width = `${
    svgOptions.width ||
    (svgOptions.height
      ? Math.floor(svgOptions.height * origSVG.ratio)
      : origSVG.viewboxWidth)
  }px`

  const height = `${
    svgOptions.height ||
    (svgOptions.width
      ? Math.floor(svgOptions.width / origSVG.ratio)
      : origSVG.viewboxHeight)
  }px`

  // const backgroundImage = svgBackgroundImage({ ...origSVG, ...svgOptions })

  const logo =
    as === 'img' ? (
      svg({
        ...origSVG,
        ...svgOptions,
        alt: title,
        className: `logo-img ${klass}`,
      })
    ) : (
      <As
        className={`logo ${klass}`}
        style={{
          backgroundImage: svgBackgroundImage({ ...origSVG, ...svgOptions }),
          width,
          height,
        }}
      >
        {title}
      </As>
    )

  return <>{props.noLink ? logo : <Link to="/">{logo}</Link>}</>
}

export default Logo;