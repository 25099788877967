import React from 'react'


class GPTUnit extends React.Component {
  constructor(props) {
    super(props)

    let {
      slot,
      sizes,
      targeting = {},
    } = props


    this.state = {
      slot,
      sizes,
      loading: true,
      targeting,
      // domID: `ad-unit-${Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000}`,
    }
  }

  componentDidMount() {
    if(typeof window !== 'undefined') {

      this.setState(
        {
          domID: `ad-unit-${
            Math.floor(Math.random() * (5000 - 1000 + 1)) + 1000
          }`,
        },
        () => {

          const slotSuffix = window.screen.availWidth >= 768 ? 'desktop' : 'mobileweb'
          const slot = `${this.state.slot}/${slotSuffix}`
          const size = this.state.sizes[slotSuffix]
          

          window.googletag = window.googletag || { cmd: [] }
          window.googletag.cmd.push(() => {
            const pubAd = window.googletag
              .defineSlot(slot, size, this.state.domID)
              .addService(window.googletag.pubads())

            for (const [k, v] of Object.entries(this.state.targeting)) {
              pubAd.setTargeting(k, v)
            }
            window.googletag.enableServices()
            window.googletag.display(this.state.domID)
          })
        })

    }
  }

  componentWillUnmount() {
    window.googletag.destroySlots()
  }

  render() {
    return (
        <div
          className="ione-gpt"
          id={this.state.domID}
        ></div>
    )
  }
}
export default GPTUnit
