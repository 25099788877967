import * as React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import SupporterCard from './card'


const List = () => {
    return (
        <StaticQuery
            query={graphql`
                query SupportersQuery {
                    allSupporter {
                        edges {
                          node {
                            description
                            help_url
                            id
                            logo
                            name
                            support_url
                          }
                        }
                    }
                }
            `}
            render={(data) => {
                return (
                    <div id="support" className="bg-black section">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={10}>
                                    <h3 className="section-heading alt display-6">
                                        <span>Support</span>
                                    </h3>
                                    <p>The work doesn't end on a local level. The same passion for education, policy, mental health, and personal finance is championed by national organizations, too. We're excited to work with them to advance those and other issues that are important to them.</p>
                                    <p>Feeling generous? Here's how to give back and support the causes being promoted by community organizations and partners at each stop on the Real Talk Drives Real Change tour. Check out the organizations and our partners to find out more about them below.</p>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                {data.allSupporter.edges.map(({ node }) => {  
                                    return (
                                        <Col key={node.id} md={4}>
                                            <SupporterCard key={node.id} {...node} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Container>
                    </div>
                )
            }}
        />
    )
}
export default List;


