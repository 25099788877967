import * as React from "react"

const VideoPlayer = ({ id, title, description, published_at, embed }) => {
    
    return (
        <div className="ratio ratio-16x9" dangerouslySetInnerHTML={{ __html: embed }} />
    )
}
export default VideoPlayer;


