import React from "react"
import Navbar from "../components/misc/navbar"
import '../scss/application.scss';
import { Helmet } from 'react-helmet'
// import { StaticImage } from "gatsby-plugin-image";
import Footer from '../components/misc/footer';

const Layout = ({ children }) => {
  return (
      <>
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          script={
            [
              // {
              //   type: 'text/javascript',
              //   src: '',
              // },
            ]
          }
          link={[
            {
              rel: 'stylesheet',
              type: 'text/css',
              href: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap',
            },
            {
              rel: 'stylesheet',
              type: 'text/css',
              href: 'https://use.fontawesome.com/releases/v5.15.4/css/all.css',
            },
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: '/images/webclip/apple-touch-icon.png',
            },
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '32x32',
              href: '/images/webclip/favicon-32x32.png',
            },
            {
              rel: 'icon',
              type: 'image/png',
              sizes: '16x16',
              href: '/images/webclip/favicon-16x16.png',
            },
            // {
            //   rel: 'mask-icon',
            //   href: '/images/webclip/safari-pinned-tab.svg',
            // },
            // {
            //   rel: 'manifest',
            //   href: '/manifest.json',
            // },
          ]}
        >
          <script
            src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
            async
            type="text/javascript"
          />
          {/* <script
            dangerouslySetInnerHTML={{
              __html: `
            window.googletag = window.googletag || { cmd: [] }
            alert('foo')
            `,
            }}
          /> */}
        </Helmet>
        <Navbar />
        <main>{children}</main>
        <Footer />
      </>
  )
}
export default Layout;