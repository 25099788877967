import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Button } from "react-bootstrap";
import moment from "moment-timezone";

const UpcomingEventCallout = () => {
  return (
    <StaticQuery
      query={graphql`
        query UpcomingEventsQuery {
          allEvent(sort: { fields: [event_starts_at], order: ASC }) {
            edges {
              node {
                id
                title
                description
                location
                venue
                venue_address_1
                venue_address_2
                google_maps_url
                date
                event_starts_at
                thumbnail
                tickets_url
                stream_url
              }
            }
          }
          allEventDate {
            edges {
              node {
                id
                date
                category
              }
            }
          }
          allCategory {
            edges {
              node {
                id
                short_name
                long_name
                theme_color
              }
            }
          }
        }
      `}
      render={(data) => {
        const now = moment().tz("US/Eastern");

        let event;

        for (let { node } of data.allEvent.edges) {
          const s = moment(node.event_starts_at).tz("US/Eastern");
          if (s.isAfter(now)) {
            event = node;
            break;
          }
        }

        if (!event) {
          return null;
        } else {
          if (typeof event.date === "string") {
            event.date_string = moment(event.date, "MM/DD/YYYY")
              .tz("US/Eastern")
              .format("MMMM D, YYYY");

            // format category & date
            let date = data.allEventDate.edges.find(
              ({ node: { date } }) => date === event.date
            );
            date = date ? date.node : null;
            if (date) {
              let category =
                typeof date.category === "string"
                  ? data.allCategory.edges.find(
                      ({ node: { short_name } }) => short_name === date.category
                    )
                  : null;
              date.category = category ? category.node : null;
              event.date = date;
            }
          }

          let venueMarkup = (
            <>
              <i className="fa fa-map-marker"></i>&nbsp;&nbsp;
              {event.venue && <>{event.venue} &bull; </>}
              {event.location}
              <small>
                {event.venue_address_1 && (
                  <>
                    <br />
                    {event.venue_address_1}
                  </>
                )}
                {event.venue_address_2 && (
                  <>
                    <br />
                    {event.venue_address_2}
                  </>
                )}
              </small>
            </>
          );

          return event.tickets_url.match(/^https?:\/\//) ? (
            <div className="text-center pb-5">
              <Button
                variant="primary"
                size="sm"
                href={event.tickets_url}
                className="cta-button"
              >
                Get Free Tickets
                <br />
                <span className="event-dateline">{event.date_string}</span>
              </Button>
              <div
                className={`event-card-location ${`text-category-${event.date.category.theme_color}`}`}
              >
                {venueMarkup}
              </div>
            </div>
          ) : null;
        }
      }}
    />
  );
};
export default UpcomingEventCallout;
