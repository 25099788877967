import * as React from "react"
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap"
import VideoCard from './card'
import VideoPlayer from './player'
// import _ from 'lodash';

class List extends React.Component {
// const List = () => {

    constructor(props) {
        super(props)
        this.state = {
            videoID: null
        }

        this.setVideo = this.setVideo.bind(this)
    }

    setVideo(videoID) {
        console.log('setVideo', videoID)
        this.setState({ videoID })
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query VideosQuery {
                        allVideo (
                            sort: { fields: [published_at], order: DESC }
                        ) {
                            edges {
                                node {
                                    id
                                    published_at
                                    title
                                    description
                                    embed
                                    thumbnail
                                }
                            }
                        }
                    }
                `}
                render={(data) => {

                    const currentVideo = this.state.videoID ? 
                                        data.allVideo.edges.find(video => video.node.id === this.state.videoID).node :
                                        data.allVideo.edges[0].node
                        

                    return (
                        <div id="videos" className="bg-black section">
                            <Container>
                                <h3 className="section-heading display-6">
                                    <span>Video{data.allVideo.edges.length > 1 ? 's' : '' }</span>
                                </h3>
                                <Row className="justify-content-md-center">
                                    <Col md={8}>
                                        {currentVideo && <div id="video-player"><VideoPlayer {...currentVideo} /></div>}
                                        {data.allVideo.edges.length > 1 && (
                                            <Row>
                                                {data.allVideo.edges.map(({ node }) => {
                                                    return (
                                                        <Col xs={6} md={4} key={node.id}>
                                                            <VideoCard {...node} current={currentVideo.id === node.id} onClickHandler={this.setVideo} />
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        )}                                        
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    )
                }}
                />
        )
    }
}
export default List;


